import { Container, Row, Col, Image } from "react-bootstrap"
import DavidAudretsch from '../../Images/David Audretsch.png'
import SabuPadmadas from '../../Images/Sabu Padmadas.png'
// import NaveenLakkur from '../../Images/Naveen Lakkur.png'
import AmarjitSingh from '../../Images/Amarjit Singh.png'
import PeterRodgers from '../../Images/Peter Rodgers.png'
import VadimGrinevich from '../../Images/Vadim Grinevich.png'
import SwarnaPrasad from '../../Images/Swarna Prasad.png'
import RamRamdas from '../../Images/Ram Ramdas.png'
const Advisor = () => {
    return (
        <section className="team-ipowerz">
           <Container>
               <Row className="">
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={DavidAudretsch} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://oneill.indiana.edu/faculty-research/directory/profiles/faculty/full-time/audretsch-david.html" target="_blank" rel="noreferrer">Prof David Audretsch</a></div>
                           <div className="profile-profession">Advisor</div>
                       </div>
                   </Col>
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={SabuPadmadas} roundedCircle  />
                           </div>
                           <div className="profile-title"><a href="https://www.southampton.ac.uk/demography/about/staff/ssp.page" target="_blank" rel="noreferrer">Prof Sabu Padmadas</a></div>
                           <div className="profile-profession">Advisor</div>
                       </div>
                   </Col>
                   {/*<Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={NaveenLakkur} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="http://www.naveenlakkur.com/" target="_blank" rel="noreferrer">Mr Naveen Lakkur</a></div>
                           <div className="profile-profession">Advisor</div>
                       </div>
                   </Col>*/}
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={AmarjitSingh} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="http://www.theindiabusinessgroup.com/site/people/profile/amarjit.singh" target="_blank" rel="noreferrer">Mr Amarjit Singh</a></div>
                           <div className="profile-profession">Advisor</div>
                       </div>
                   </Col>
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={PeterRodgers} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://www.southampton.ac.uk/business-school/about/staff/pwr1n20.page" target="_blank" rel="noreferrer">Prof Peter Rodgers</a></div>
                           <div className="profile-profession">Senior Fellow</div>
                       </div>
                   </Col>
               </Row>
               <Row className="team-ipowerz">
                    <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={VadimGrinevich} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://www.southampton.ac.uk/business-school/about/staff/vg4g13.page" target="_blank" rel="noreferrer">Dr Vadim Grinevich</a></div>
                           <div className="profile-profession">Senior Fellow</div>
                       </div>
                   </Col>
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={SwarnaPrasad} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://www.southampton.ac.uk/business-school/about/staff/sp1f11.page" target="_blank" rel="noreferrer">Dr Swarna Prasad</a></div>
                           <div className="profile-profession">Senior Fellow</div>
                       </div>
                   </Col>
                   <Col md="3" lg="3" sm="3">
                       <div className="profile-block">
                           <div className="profile-image">
                               <Image src={RamRamdas} roundedCircle />
                           </div>
                           <div className="profile-title"><a href="https://in.linkedin.com/in/ramramdas" target="_blank" rel="noreferrer">Dr Ram Ramdas</a></div>
                           <div className="profile-profession">Senior Fellow</div>
                       </div>
                   </Col>
               </Row>
           </Container>
        </section>
    )
}
export default Advisor;