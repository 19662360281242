import { NavbarMenu, TopBar } from '../components/navbar'
import TeamHeaderTitle from "./Team/TeamTopHeader"
import '../css/AboutUs.css'
import IpowerzTeam from "./Team/IpowerzTeam"
import Advisor from "./Team/Advisor"
import NewsLetter from "./Home/NewsLetter"
import Footer from "./Home/Footer"
import ScrollTop from 'react-scrolltop-button';
import React, { useEffect, useState } from 'react'
const Team = () => {
    const [navBarPosition,setnavbarPosition]=useState('')
    useEffect(()=>{
        window.addEventListener("scroll", handleScroll);
    })
    const handleScroll = () =>{
        if (window.scrollY > 100) {
          setnavbarPosition('fixed-top')
    } else {
        setnavbarPosition('')
    }
    }
    return(
        <>
        <section className={navBarPosition}>
        <TopBar />
        <NavbarMenu activeName="Team" />
        </section>
        <section className="bg-team">
        <TeamHeaderTitle />
        <IpowerzTeam />
        <Advisor />
        </section>
        <NewsLetter />
        <Footer />
        <ScrollTop 
        text="🠙"
        style={{ backgroundColor: "#f7560f", border: 'none', paddingTop: "0px" ,paddingBottom: "0px", color: "#ffffff", fontSize: "32px" }}
        className="scroll-your-role"
        />
        </>
    )
}
export default Team;