import { Container, Row, Col } from 'react-bootstrap'
import BannerImage from '../../Images/iPowerz-Services.png'
export const Banner = () => {
    return(
        <section className="banner">
            <Container>
                <Row>
                    <Col md="6" lg="6" className="BannerImageMobile">
                        <div className="image">
                            <img src={BannerImage} alt="Banner" title="Banner" className="w-100" />
                        </div>
                    </Col>
                    <Col md="6" lg="6">
                        <div className="banner-content">
                            <h4 className="bannerTitle">One stop <span>end-to-end</span> platform for Business</h4>
                            <p>iPowerz is the digital ecosystem for fast-tracking 99% of businesses: start-ups, microenterprises, small businesses and scale-ups through real time collaboration and access to the global marketplace.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    );
}