import { useEffect } from "react";
import { withRouter } from "react-router-dom";

const ScrollToTop = ({ location, children }) => {
  useEffect(() => {
    console.log('Executed');
    window.scroll({ top: 0, behavior: 'smooth' }) 
  }, [location.pathname]);

  return children;
};

export default withRouter(ScrollToTop);
